.form-group{
    display:inline-block;
    width:50%;
}

/* input[type=text] {
    width: 100%;
    padding: 12px 20px;
    box-sizing: border-box;
  } */

input[type=button], input[type=submit], input[type=reset] {
background-color: #fff;
color: #2e2e2e;
cursor: pointer;
}
